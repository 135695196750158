<template>
  <div class="bodycenter">
    <div class="pagecenter">
      <div
        class="headercenter"
        :style="{'background-image': ($t('lang') === 'zh' ? 'url(/static/images/monitorCommon/monitorcenter_head.png)' : 'url(/static/images/monitorCommon/monitorcenter_head_en.png)')}">
        <div class="monitorcenterlogo">
          <img
            class="large"
            :src="$tenant.bigLogo"
            :style="{'height': '40px', 'margin-left': ($t('lang') === 'zh' ? '10px' : '90px')}"/>
        </div>
      </div>
      <div class="containercenter">
        <el-tabs v-model="activeName">
          <el-tab-pane :label="$t('route.map')" name="first">
            <gis-map :show-alarm-table="true" style="width: 100%;height:100%;border:#ccc solid 1px;">
            </gis-map>
          </el-tab-pane>
          <el-tab-pane :label="$t('monitor.monitoring')" name="second">
            <div ref="content" style="position: absolute;z-index: 10;top: 0px;overflow: auto;width: 100%;height: 100%;">
              <table v-if="false" style="top: 40px;">
                <tr>
                  <td v-for="(personnel,index) in selectElevator" :key="index" style="float: left">
                    <small-elevator
                      :register-no="personnel.tag"
                      :name="personnel.name"
                      :b-width="smallElevatorWidth"
                      :b-height="smallElevatorHeight"
                      :click-tag="clickTag"></small-elevator>
                  </td>
                </tr>
              </table>
              <div  :style="[{width:`${monitorWidth}px`,top: '40px',margin:'auto'},{'text-align': 'center'},{'background-color': '#00C842'}]">
                <div v-for="(personnel,index) in selectElevator" :key="index"
                     style="float: left;border:1px solid #ffffff">
                  <small-elevator
                    :register-no="personnel.tag"
                    :name="personnel.name"
                    :b-width="smallElevatorWidth"
                    :b-height="smallElevatorHeight"
                    :click-tag="clickTag"></small-elevator>
                </div>
              </div>
              <div style="width: 100%;top: 16px;overflow: hidden;margin:auto;text-align: center">
                <el-pagination
                  class="page"
                  :page-size="pageSize"
                  :current-page="currentPage"
                  layout="total, prev, pager, next, jumper"
                  :total="total"
                  @current-change="handleCurrentChange"></el-pagination>
              </div>
            </div>

            <div class="wrapper1" style="top: 0px;">
              <div
                class="elevatorTree"
                :class="{elevatorTreeCollapse:!expandFlag}"
                style="position:absolute;z-index:20">
                <vm-tree-multi class="elevatorTreeLeft" @submit="onSubmit"></vm-tree-multi>
                <div class="expand" @click="expand">
                  {{expandFlag ? $t("monitor.close") : $t("monitor.open")}}
                </div>
              </div>
              <div
                :style="{ 'bottom':tBottom}"
                style="transition: 0.5s;position:absolute;z-index:33;height: 470px;right: 0px">
                <alarm-table @clickExpandTable="clickExpandTable"></alarm-table>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
  import gisMap from "./GisMap.vue";
  import VmTreeMulti from "../../components/VmTreeMulti.vue";
  import smallElevator from "./smallElevator";
  import alarmTable from "./AlarmTable.vue";

  export default {
    components: {gisMap, VmTreeMulti, smallElevator, alarmTable},
    data() {
      return {
        activeName: "first",
        loading: false,
        tableFlag: false,
        selectElevator: [],
        smallElevatorWidth: "250px",
        smallElevatorHeight: "280px",
        expandFlag: false,
        tBottom: "-440px",
        pageSize: 30,
        currentPage: 1,
        total: 0,
        clickTag: true,
        monitorWidth: 0,
        allSelectElevator: [],
      };
    },
    mounted() {
      document.title = this.$t("route.monitorCenter");
    },
    methods: {
      handleCurrentChange(page) {
        this.clickTag = !this.clickTag;
        this.selectElevator = [];
        //        page //第几页
        if (page < Math.ceil(this.allSelectElevator.length / this.pageSize)) {
          for (let i = 0; i < this.pageSize; i++) {
            this.selectElevator[i] = this.allSelectElevator[(page - 1) * this.pageSize + i];
          }
        } else {
          for (let i = 0; i < this.allSelectElevator.length - (page - 1) * this.pageSize; i++) {
            this.selectElevator[i] = this.allSelectElevator[(page - 1) * this.pageSize + i];
          }
        }
      },
      onSubmit(data) {
        this.selectElevator = [];
        this.clickTag = !this.clickTag;
        this.allSelectElevator = data;
        this.total = data.length;
        if (Math.ceil(this.allSelectElevator.length / this.pageSize) > 1) {
          for (let i = 0; i < this.pageSize; i++) {
            this.selectElevator[i] = this.allSelectElevator[i];
          }
        } else {
          for (let i = 0; i < this.allSelectElevator.length; i++) {
            this.selectElevator[i] = this.allSelectElevator[i];
          }
        }
        this.expandFlag = false;
        const clientWidth = this.$refs.content.clientWidth;
        this.monitorWidth = (parseInt(clientWidth / 252) - 1) * 252;
        console.log(clientWidth + "_" + (parseInt(clientWidth / 252) - 1) + "_" + this.monitorWidth);
      },
      expand() {
        this.expandFlag = !this.expandFlag;
      },
      clickExpandTable() {
        this.tableFlag = !this.tableFlag;
        if (this.tableFlag) {
          this.tBottom = "0px";
        } else {
          this.tBottom = "-440px";
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
.bodycenter {
  overflow: auto;
  background-color: #005f8a;
}

.pagecenter {
  margin: 0 auto;
  position: relative;
}

.headercenter {
  width: 100%;
  height: 76px;
  /*background-image: url(/static/images/monitorCommon/monitorcenter_head.png);*/
  background-repeat: repeat-x;
}

.containercenter {
  clear: both;
  overflow: auto;
  background-color: white;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  background-color: #005f8a;
}

.monitorcenterlogo {
  width: 360px;
  height: 50px;
  float: left;
  margin-top: 17px;
  margin-left: 150px;
}

.wrapper1 {
  background-color: white;
  position: relative;
  height: calc(100vh - 80px);

  .elevatorTree {
    position: absolute;
    z-index: 20;
    transition: 0.5s;
    left: 0;
    height: calc(100% - 100px);
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);

    .expand {
      width: 17px;
      height: 38px;
      background-color: #2D8CF0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      color: white;
      padding: 10px 5px;
      cursor: pointer;
    }
  }

  .elevatorTreeCollapse {
    transition: 0.5s;
    left: -500px;
  }
}
</style>
